import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// @material-ui/icons
import Chat from '@material-ui/icons/Chat'
import VerifiedUser from '@material-ui/icons/VerifiedUser'
import Fingerprint from '@material-ui/icons/Fingerprint'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import InfoArea from 'components/InfoArea/InfoArea.js'

import styles from 'assets/jss/material-kit-react/views/landingPageSections/productStyle.js'
import { faHtml5 } from '@fortawesome/free-brands-svg-icons'

const useStyles = makeStyles(styles)

export default function ProductSection() {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={8}>
          <h3 className={classes.title}>Let{"'"}s start saving!</h3>
          <h5 className={classes.description}>
            Grown from a passion of finding the best deals and promotion. At My
            Specials SA we proud ourself by saving South-African households
            money. We bring the latest catalogues, deals and specials - right to
            your finger tips. Our goal and vision is to become SA's leading
            online portal for finding discounts and keeping your hard earned
            cash going that mile further.
          </h5>
          <h5 className={classes.description}></h5>
          <h3 className={classes.title}>Work with us</h3>
          <faHtml5 className={classes.description}>
            Want to ask us a question or advertise with us? Pop us a message,
            and we will get back to you as soon as possible. Or send a message
            to{' '}
            <a href={'mailto:admin@myspecialssa.co.za'}>
              admin@myspecialssa.co.za
            </a>{' '}
            for any enquiries.
          </faHtml5>
        </GridItem>
      </GridContainer>
    </div>
  )
}
